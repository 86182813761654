import React from "react";
import styled from "@emotion/styled";

import { Menu, MenuButton, Select, MenuList, MenuItem } from "@chakra-ui/react";
import { capitalize } from "../../globals/utils";
import FilterHeader from "./FormHeader";

interface FilterSelectProps {
  header: string;
  options: string[];
  onSelect: (value: string) => void;
  selected: string;
  noOptionFmt?: boolean;
  disabled?: boolean;
}

export const StyledMenuList = styled(MenuList)`
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--chakra-colors-gray-800);
    border-radius: 20px;
    border: 2px solid var(--chakra-colors-gray-800);
  }
`;

const FilterSelect = (props: FilterSelectProps): React.ReactElement => {
  const fmt = (value: string) => {
    if (props.noOptionFmt) return value;
    return capitalize(value);
  };

  return (
    <>
      <FilterHeader value={props.header} />
      <Menu>
        <MenuButton width="100%" disabled={!!props.disabled}>
          <Select
            variant="filled"
            placeholder={fmt(props.selected)}
            disabled={!!!!props.disabled}
          />
        </MenuButton>
        <StyledMenuList maxHeight="202px" overflowY="auto">
          {props.options.map((option) => (
            <MenuItem key={option} onClick={() => props.onSelect(option)}>
              {fmt(option)}
            </MenuItem>
          ))}
        </StyledMenuList>
      </Menu>
    </>
  );
};

export default FilterSelect;
