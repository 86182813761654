import React, { useState } from "react";
import { capitalize } from "lodash";
import {
  Box,
  Text,
  SimpleGrid,
  useColorModeValue,
  useToast,
  Button,
} from "@chakra-ui/react";
import { AxiosError, AxiosResponse } from "axios";

import Header from "../../components/layout/Header";
import colors from "../../components/theme/colors";
import { ErrorResponse } from "../../api/types";
import { FilterDateSelect } from "../../components/filter";
import { ListCryptoSpreadParams, downloadCryptoSpread } from "../../api/api";
import { useMutation } from "react-query";
import moment from "moment";
import fileDownload from "js-file-download";

const DEFAULT_START_DATE = moment()
  .subtract(1, "month")
  .startOf("day")
  .format("YYYY-MM-DD");
const DEFAULT_END_DATE = moment().startOf("day").format("YYYY-MM-DD");
const DEFAULT_DATE_VALUE = {
  start: DEFAULT_START_DATE,
  end: DEFAULT_END_DATE,
};

const Reports = (): React.ReactElement => {
  const toast = useToast();
  const [params, setParams] = useState<ListCryptoSpreadParams>(
    DEFAULT_DATE_VALUE
  );

  const { isLoading, mutate } = useMutation(
    (payload: ListCryptoSpreadParams) => downloadCryptoSpread(payload),
    {
      onSuccess: (response: AxiosResponse<Blob>) => {
        const timestamp = moment().unix();
        const filename = `crypto_spread_report_${timestamp}.csv`;
        fileDownload(response.data, filename, "text/csv");
      },
      onMutate: () => {
        toast({
          title: "Your download will begin shortly",
          status: "info",
        });
      },
      onError: (error: AxiosError<ErrorResponse>) => {
        toast({
          title: error?.response?.data.message
            ? capitalize(error?.response?.data.message)
            : "Unable to download report",
          status: "error",
        });
      },
    }
  );

  const handleDownload = () => {
    mutate(params);
  };

  const cardBg = useColorModeValue(
    colors.cardBackgroundLight,
    colors.cardBackgroundDark
  );

  return (
    <Box>
      <Header title="Reports" />
      <SimpleGrid columns={2} spacing={10}>
        <Box bg={cardBg} borderRadius="lg" p="1rem">
          <Text fontSize="xl">Crypto Spread Revenue Report</Text>
          <Text fontSize="md" opacity="0.6">
            Select the date range you want to download.
          </Text>

          <div style={{ marginBottom: "2rem" }}>
            <FilterDateSelect
              header="Trade Date"
              onDateSelect={(start, end) => {
                setParams(() => ({
                  start: start?.startOf("day").format("YYYY-MM-DD"),
                  end: end?.startOf("day").format("YYYY-MM-DD"),
                }));
              }}
              allowNull={false}
              defaultSelect="30-days"
              filterDate={(date) => date.getDay() !== 0 && date.getDay() !== 6}
              hiddenOptions={["24-hours"]}
            />
          </div>

          <Button
            isDisabled={!params.start || !params.end}
            isLoading={isLoading}
            onClick={handleDownload}
          >
            Download CSV
          </Button>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default Reports;
