import React, { useState } from "react";
import { Menu, MenuButton, MenuList, MenuItem, Select } from "@chakra-ui/react";
import moment from "moment";
import DateRangePicker from "../general/DatePicker";
import FilterHeader from "./FormHeader";
import { RangeType } from ".";

interface FilterDateSelectProps {
  header: string;
  onDateSelect: (
    startDate: moment.Moment | null,
    endDate: moment.Moment | null
  ) => void;
  onSelect?: (value: string) => void;
  disabled?: boolean;
  allowNull?: boolean;
  defaultSelect?: string;
  filterDate?: (date: Date) => boolean;
  hiddenOptions?: typeof DATE_RANGE_TIMEFRAMES[number][];
  value?: RangeType;
}

interface DropdownOption {
  label: string;
  key: typeof DATE_RANGE_TIMEFRAMES[number];
}

const DATE_RANGE_TIMEFRAMES = [
  "anytime",
  "24-hours",
  "7-days",
  "14-days",
  "30-days",
  "60-days",
  "range",
] as const;

const DATE_RANGE_OPTIONS: DropdownOption[] = [
  {
    label: "Last 24 Hours",
    key: "24-hours",
  },
  {
    label: "Last 7 Days",
    key: "7-days",
  },
  {
    label: "Last 14 Days",
    key: "14-days",
  },
  {
    label: "Last 30 Days",
    key: "30-days",
  },
  {
    label: "Last 60 Days",
    key: "60-days",
  },
  {
    label: "Range",
    key: "range",
  },
];

const FilterDateSelect = ({
  header,
  onDateSelect,
  onSelect,
  disabled,
  allowNull = true,
  defaultSelect = "anytime",
  filterDate,
  hiddenOptions = [],
  value,
}: FilterDateSelectProps): React.ReactElement => {
  const [selected, setSelected] = useState(defaultSelect);

  const timeframeSelect = (timeframe: string) => {
    setSelected(timeframe);
    onSelect?.(timeframe);
    onDateSelect(null, null);

    if (timeframe !== "anytime" && timeframe !== "range") {
      const [amount, unit] = timeframe.split("-");
      onDateSelect(
        moment().subtract(amount, unit as "days" | "hours"),
        moment()
      );
    }
  };

  const dateRangeSelect = (startDate: Date, endDate: Date) => {
    onDateSelect(moment(startDate), moment(endDate));
  };

  return (
    <>
      <FilterHeader value={header} />
      <Menu>
        <MenuButton width="100%" disabled={!!disabled}>
          <Select
            variant="filled"
            placeholder={
              DATE_RANGE_OPTIONS.find((option) => option.key === selected)
                ?.label ?? "Anytime"
            }
            disabled={!!disabled}
          />
        </MenuButton>
        <MenuList zIndex={2}>
          {allowNull && (
            <MenuItem onClick={() => timeframeSelect("anytime")}>
              Anytime
            </MenuItem>
          )}
          {(allowNull ? DATE_RANGE_OPTIONS : DATE_RANGE_OPTIONS.slice(1))
            .filter((option) => !hiddenOptions.includes(option.key))
            .map((option) => (
              <MenuItem
                key={option.key}
                onClick={() => timeframeSelect(option.key)}
              >
                {option.label}
              </MenuItem>
            ))}
        </MenuList>
      </Menu>

      {selected === "range" && (
        <DateRangePicker
          onChange={dateRangeSelect}
          filterDate={filterDate}
          value={value}
        />
      )}
    </>
  );
};

export default FilterDateSelect;
