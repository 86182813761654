import moment from "moment";
import UUID from "../general/UUID";
import ExportToCsv from "./ExportButton";
import OrderDetails from "../page/OrderDetails";

import React, { useEffect, useState } from "react";

import {
  FilterDrawer,
  dateRangeToPill,
  FilterDateSelect,
  FilterNumber,
  FilterSelect,
  FilterString,
  NumRange,
  rangeToPill,
  RangeType,
  FilterButton,
  QueryKey,
} from "../filter";

import { Column } from "react-table";
import { getOrders } from "../../api/api";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { capitalize } from "../../globals/utils";
import { Order, OrdersParams } from "../../api/types";
import { Table } from "@alpacahq/alpaca-component-library";
import { CheckIcon, CloseIcon, TimeIcon } from "@chakra-ui/icons";
import { Box, Flex, Spacer, Text, useToast } from "@chakra-ui/react";

interface OrdersTableProps {
  accountID?: string;
  currency: string;
}

export const ORDER_STATUSES = [
  "all",
  "accepted",
  "accepted_for_bidding",
  "calculated",
  "canceled",
  "done_for_day",
  "expired",
  "filled",
  "held",
  "new",
  "partially_filled",
  "pending_cancel",
  "pending_new",
  "pending_replace",
  "rejected",
  "replaced",
  "stopped",
  "suspended",
];

export const ORDER_SIDES = [
  "all",
  "buy",
  "buy_minus",
  "sell",
  "sell_short",
  "sell_short_exempt",
  "undisclosed",
  "cross",
  "cross_short",
];

const QUERY_LIMIT = 500;
const DEFAULT_SELECTED_DATES: RangeType = [
  moment().subtract(14, "days"),
  moment(),
];

const OrdersTable = ({
  accountID,
  currency,
}: OrdersTableProps): React.ReactElement => {
  const [filterOpen, setFilterOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<Order | undefined>();
  const toast = useToast();
  const navigate = useNavigate();
  const aID = accountID;

  const [orderIDFilter, setOrderIDFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const [sideFilter, setSideFilter] = useState("all");
  const [symbols, setSymbols] = useState("");
  const [qtyFilter, setQtyFilter] = useState<NumRange>([null, null]);
  const [selectedDates, setSelectedDates] = useState<RangeType>(
    DEFAULT_SELECTED_DATES
  );
  const [filterKey, setFilterKey] = useState<QueryKey>(["orders", aID]);
  const [applyFilter, setApplyFilter] = useState(true);
  const [allOrders, setAllOrders] = useState<Order[]>([]);

  const pageSize = 25;

  // Paginated query
  const fetchOrders = ({ pageParam = moment() }) => {
    const params: OrdersParams = {
      id: orderIDFilter,
      status: statusFilter,
      limit: QUERY_LIMIT,
      until: pageParam.toISOString(),
      side: sideFilter,
      symbols,
    };

    // qty filter
    if (qtyFilter) {
      if (qtyFilter[0]) params.qty_above = qtyFilter[0];
      if (qtyFilter[1]) params.qty_below = qtyFilter[1];
    }

    // date filter
    if (selectedDates[0] && selectedDates[1]) {
      params.after = selectedDates[0].startOf("day").toISOString();
      params.until = selectedDates[1].endOf("day").toISOString();
    }

    return getOrders(params, aID);
  };

  const queryKeys = [
    "orders",
    statusFilter,
    sideFilter,
    symbols,
    qtyFilter,
    selectedDates,
    aID,
  ];
  useEffect(() => {
    if (applyFilter) setFilterKey(queryKeys);
  }, [...queryKeys, applyFilter]);

  const hasFiltersApplied =
    statusFilter !== "all" ||
    sideFilter !== "all" ||
    symbols !== "" ||
    !qtyFilter.every((e) => e == null) ||
    !selectedDates.every((e) => e === null);

  const ordersQuery = useQuery(filterKey, fetchOrders, {
    onSuccess: (data) => {
      if (!hasFiltersApplied) {
        setAllOrders(data);
      }
      setApplyFilter(false);
    },
  });

  if (ordersQuery.isError) {
    toast({
      title: "An error occurred fetching orders",
      description: (ordersQuery.error as Error).message,
      status: "error",
    });
  }

  const orders = ordersQuery.data || [];

  const openFilter = () => {
    setFilterOpen(true);
    setApplyFilter(false);
  };

  const filterPills = {
    status: statusFilter,
    side: sideFilter,
    symbols,
    qty: rangeToPill("qty", qtyFilter),
    dates: dateRangeToPill(selectedDates),
  };

  const removeFilter = (filterID: string) => {
    if (filterID === "side") setSideFilter("all");
    if (filterID === "status") setStatusFilter("all");
    if (filterID === "symbols") setSymbols("");
    if (filterID === "qty") setQtyFilter([null, null]);
    if (filterID === "dates") setSelectedDates([null, null]);
    setApplyFilter(true);
    ordersQuery.refetch();
  };

  const csvHeaders = [
    "account_id",
    "id",
    "side",
    "symbol",
    "filled_qty",
    "notional",
    "status",
    "updated_at",
    "formatted_updated_at",
  ];

  const filterBtn = aID ? (
    <Box mt="-6.5rem" float="right" display="flex">
      <ExportToCsv
        data={orders}
        allData={allOrders}
        givenHeaders={csvHeaders}
      />
      <FilterButton
        filterPills={hasFiltersApplied ? filterPills : {}}
        openFilter={openFilter}
        removeFilter={removeFilter}
      />
    </Box>
  ) : (
    <Flex>
      <Spacer />
      <Box mb="1rem" display="flex">
        <ExportToCsv
          data={orders}
          allData={allOrders}
          givenHeaders={csvHeaders}
        />
        <FilterButton
          filterPills={hasFiltersApplied ? filterPills : {}}
          openFilter={openFilter}
          removeFilter={removeFilter}
        />
      </Box>
    </Flex>
  );

  const getTextColor = (text: string) => {
    const styling = {
      success: [
        "new",
        "partially_filled",
        "filled",
        "done_for_day",
        "buy",
        "buy_minus",
        "calculated",
      ],
      warning: ["replaced", "pending_cancel"],
      error: [
        "canceled",
        "rejected",
        "expired",
        "sell",
        "sell_short",
        "sell_short_exempt",
        "stopped",
      ],
    };

    if (styling.success.includes(text)) {
      return "success";
    } else if (styling.warning.includes(text)) {
      return "warning";
    } else if (styling.error.includes(text)) {
      return "error";
    }

    return "inherit";
  };

  const getTextIcon = (text: string) => {
    const styling = {
      success: ["filled"],
      warning: ["pending", "held", "pending_cancel", "pending_new", "expired"],
      failed: ["failed", "canceled", "rejected"],
    };

    if (styling.success.includes(text)) {
      return <CheckIcon mr="4px" />;
    } else if (styling.warning.includes(text)) {
      return <TimeIcon mr="4px" />;
    } else if (styling.failed.includes(text)) {
      return <CloseIcon mr="4px" />;
    }

    return null;
  };

  const columns: Column<Order>[] = [
    {
      Header: "Account ID",
      accessor: ({ account_id }) => (
        <UUID
          onClick={() => navigate(`/accounts/${account_id || accountID}`)}
          value={account_id || accountID}
        />
      ),
    },
    {
      Header: "Order ID",
      accessor: (order) => (
        <UUID onClick={() => setSelectedOrder(order)} value={order.id} />
      ),
    },
    {
      Header: "Side",
      accessor: ({ side }) => (
        <Text color={getTextColor(side)}>{capitalize(side)}</Text>
      ),
    },
    { Header: "Symbol", accessor: "symbol" },
    { Header: "Filled Qty", accessor: "filled_qty" },
    {
      Header: "Status",
      accessor: ({ status }) => (
        <Text color={getTextColor(status)}>
          {getTextIcon(status)}
          {capitalize(status)}
        </Text>
      ),
    },
    {
      Header: "Submitted At",
      accessor: ({ submitted_at }) => {
        const date = moment(submitted_at);
        return (
          <Flex>
            <Text>{date.format("YYYY-MM-DD")}</Text>
            <Text opacity={0.5}>&nbsp;{date.format("hh:mm a")}</Text>
          </Flex>
        );
      },
    },
    {
      Header: "Updated At",
      accessor: ({ updated_at }) => {
        const date = moment(updated_at);
        return (
          <Flex>
            <Text>{date.format("YYYY-MM-DD")}</Text>
            <Text opacity={0.5}>&nbsp;{date.format("hh:mm a")}</Text>
          </Flex>
        );
      },
    },
    {
      Header: "Expires At",
      accessor: ({ expires_at }) => {
        if (!expires_at) {
          return <Text></Text>;
        }

        const date = moment(expires_at);
        return (
          <Flex>
            <Text>{date.format("YYYY-MM-DD")}</Text>
            <Text opacity={0.5}>&nbsp;{date.format("hh:mm a")}</Text>
          </Flex>
        );
      },
    },
  ];

  return (
    <Box>
      <OrderDetails
        isOpen={!!selectedOrder}
        onClose={() => setSelectedOrder(undefined)}
        order={selectedOrder}
        currency={currency}
      />
      <FilterDrawer
        isOpen={filterOpen}
        onClose={() => setFilterOpen(false)}
        onApply={() => {
          setApplyFilter(true);
          setFilterOpen(false);
        }}
      >
        <FilterString header="Order ID" onChange={setOrderIDFilter} />
        <FilterSelect
          header="Status"
          options={ORDER_STATUSES}
          onSelect={setStatusFilter}
          selected={statusFilter}
        />
        <FilterSelect
          header="Side"
          options={ORDER_SIDES}
          onSelect={setSideFilter}
          selected={sideFilter}
        />
        <FilterString header="Symbol" onChange={setSymbols} />
        <FilterNumber
          header="Qty"
          minRange={0}
          maxRange={1000}
          step={1}
          onChange={setQtyFilter}
        />
        <FilterDateSelect
          header="Submitted At"
          onDateSelect={(st, ed) => setSelectedDates([st, ed])}
        />
      </FilterDrawer>
      {filterBtn}
      <Table
        columns={columns}
        data={orders}
        isLoading={ordersQuery.isLoading}
        defaultPerPage={pageSize}
        pagination
        paginationPosition="top"
      />
    </Box>
  );
};

export default OrdersTable;
