import moment from "moment";
import Tab from "../components/general/Tab";
import UUID from "../components/general/UUID";
import Header from "../components/layout/Header";
import ExportToCsv from "../components/tables/ExportButton";

import React, { useEffect, useState, useRef } from "react";

import {
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Flex,
  Spacer,
  useToast,
  Box,
  Text,
} from "@chakra-ui/react";

import {
  FilterDrawer,
  dateRangeToPill,
  FilterDateSelect,
  FilterSelect,
  FilterString,
  RangeType,
  FilterButton,
  QueryKey,
} from "../components/filter";

import { Column } from "react-table";
import { getJournals } from "../api/api";
import { capitalize, fmtLCTMoney, formatDate } from "../globals/utils";
import { useInfiniteQuery } from "react-query";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { Table } from "@alpacahq/alpaca-component-library";
import { CheckIcon, CloseIcon, TimeIcon } from "@chakra-ui/icons";
import { Journal, JournalStatus, JournalType } from "../api/types";

const Journals = (): React.ReactElement => {
  const [selectedTab, setSelectedTab] = useState(0);
  const toast = useToast();
  const navigate = useNavigate();

  const fetchRefId = useRef(0);
  const skipPageResetRef = useRef(false);

  // Filter
  const [filterOpen, setFilterOpen] = useState(false);
  const [statusFilter, setStatusFilter] = useState<JournalStatus>("all");
  const [selectedDates, setSelectedDates] = useState<RangeType>([null, null]);
  const [filterKey, setFilterKey] = useState<QueryKey>(["journals"]);
  const [journalIDFilter, setJournalIDFilter] = useState("");
  const [applyFilter, setApplyFilter] = useState(false);
  const [allJournals, setAllJournals] = useState<Journal[]>([]);

  // journal type depending on tab selected
  const typeFilter: JournalType = selectedTab === 0 ? "JNLC" : "JNLS";

  const pageSize = 25;

  // Paginated query
  const fetchJournals = ({ pageParam = moment() }) => {
    const params = {
      entry_type: typeFilter,
      status: statusFilter,
      before: pageParam.toISOString(),
      after: moment(pageParam).subtract(3, "months").toISOString(),
      id: journalIDFilter,
    };

    if (applyFilter) {
      if (selectedDates[0] && selectedDates[1]) {
        params.after = selectedDates[0].startOf("day").toISOString();
        params.before = selectedDates[1].endOf("day").toISOString();
      }
    }

    return getJournals(params);
  };

  const queryKeys = ["journals", statusFilter, selectedDates];
  useEffect(() => {
    if (applyFilter) setFilterKey(queryKeys);
  }, [...queryKeys, applyFilter]);

  const journalsQuery = useInfiniteQuery(filterKey, fetchJournals, {
    getNextPageParam: (lastPage) => {
      if (lastPage.length !== 0) {
        return lastPage[lastPage.length - 1].system_date;
      }
    },
    onSuccess: (data) => {
      if (!applyFilter) {
        const res = data?.pages?.flat() || [];
        setAllJournals(res);
      }
    },
  });

  const journals = (journalsQuery.data?.pages ?? []).flat();

  if (journalsQuery.isError) {
    toast({
      title: "An error occurred fetching journal entries",
      description: (journalsQuery.error as Error).message,
      status: "error",
    });
  }

  const filterPills = {
    status: statusFilter,
    dates: dateRangeToPill(selectedDates),
  };

  const removeFilter = (filterID: string) => {
    if (filterID === "status") setStatusFilter("all");
    if (filterID === "dates") setSelectedDates([null, null]);
  };

  const openFilter = () => {
    setFilterOpen(true);
    setApplyFilter(false);
  };

  const FilterPills = (
    <Box display="flex">
      <ExportToCsv data={journals} allData={allJournals} />
      <FilterButton
        filterPills={applyFilter ? filterPills : {}}
        openFilter={openFilter}
        removeFilter={removeFilter}
      />
    </Box>
  );

  const getTextColor = (text: string) => {
    const styling = {
      success: ["executed"],
      warning: ["queued", "sent_to_clearing", "pending"],
      error: ["rejected", "canceled", "refused", "deleted"],
    };

    if (styling.success.includes(text)) {
      return "success";
    } else if (styling.warning.includes(text)) {
      return "warning";
    } else if (styling.error.includes(text)) {
      return "error";
    }

    return "inherit";
  };

  const getTextIcon = (text: string) => {
    const styling = {
      success: ["executed"],
      warning: ["queued", "sent_to_clearing", "pending"],
      error: ["rejected", "canceled", "refused", "deleted"],
    };

    if (styling.success.includes(text)) {
      return <CheckIcon mr="4px" />;
    } else if (styling.warning.includes(text)) {
      return <TimeIcon mr="4px" />;
    } else if (styling.error.includes(text)) {
      return <CloseIcon mr="4px" />;
    }

    return null;
  };

  const columns: Column<Journal>[] = [
    {
      Header: "Journal ID",
      accessor: ({ id }) => <UUID value={id} />,
    },
    { Header: "Entry Type", accessor: "entry_type" },
    {
      Header: "From Account",
      accessor: ({ from_account }) => (
        <UUID
          onClick={() => navigate(`/accounts/${from_account}`)}
          value={from_account}
        />
      ),
    },
    {
      Header: "To Account",
      accessor: ({ to_account }) => (
        <UUID
          onClick={() => navigate(`/accounts/${to_account}`)}
          value={to_account}
        />
      ),
    },
    {
      Header: "Net Amount",
      accessor: ({ net_amount, currency }) => fmtLCTMoney(net_amount, currency),
    },
    {
      Header: "Status",
      accessor: ({ status }) => (
        <Text color={getTextColor(status)}>
          {getTextIcon(status)}
          {capitalize(status)}
        </Text>
      ),
    },
    {
      Header: "Settle Date",
      accessor: ({ settle_date }) => {
        if (!settle_date) {
          return <Text>-</Text>;
        }

        return (
          <Flex>
            <Text>{formatDate(settle_date, "YYYY-MM-DD")}</Text>
            <Text opacity={0.5}>
              &nbsp;{formatDate(settle_date, "hh:mm a")}
            </Text>
          </Flex>
        );
      },
    },
  ];

  const onFetchData = () => {
    if (++fetchRefId.current !== fetchRefId.current) {
      return;
    }

    skipPageResetRef.current = true;
  };

  return (
    <Box>
      <FilterDrawer
        isOpen={filterOpen}
        onClose={() => setFilterOpen(false)}
        onApply={() => {
          setApplyFilter(true);
          setFilterOpen(false);
        }}
      >
        <FilterSelect
          header="Status"
          options={[
            "all",
            "pending",
            "queued",
            "executed",
            "canceled",
            "deleted",
            "rejected",
          ]}
          onSelect={(status) => setStatusFilter(status as JournalStatus)}
          selected={statusFilter}
        />
        <FilterDateSelect
          header="Settle Date"
          onDateSelect={(st, ed) => setSelectedDates([st, ed])}
        />
        <FilterString header="Journal ID" onChange={setJournalIDFilter} />
      </FilterDrawer>
      <Header title="Transactions > Journals" />
      {isMobile && FilterPills}
      <Tabs onChange={(idx) => setSelectedTab(idx)}>
        <Flex>
          <TabList>
            <Tab text="Cash" isSelected={selectedTab === 0} />
          </TabList>
          <Spacer />
          {!isMobile && FilterPills}
        </Flex>
        <TabPanels>
          <TabPanel>
            <Table
              columns={columns}
              isLoading={journalsQuery.isLoading}
              data={journals}
              defaultPerPage={pageSize}
              onFetchData={onFetchData}
              pagination
              paginationPosition="top"
              skipPageResetRef={skipPageResetRef}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default Journals;
