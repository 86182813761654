import React from "react";
import { Input } from "@chakra-ui/react";
import FilterHeader from "./FormHeader";

interface FilterStringProps {
  header: string;
  onChange: (value: string) => void;
  list?: string;
  value?: string;
}

const FilterString = (props: FilterStringProps): React.ReactElement => {
  return (
    <>
      <FilterHeader value={props.header} />
      <Input
        variant="filled"
        placeholder={`Enter ${props.header}`}
        onChange={(e) => props.onChange(e.target.value)}
        list={props.list}
        value={props.value}
      />
    </>
  );
};

export default FilterString;
