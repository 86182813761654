import {
  Api,
  CountryRiskIcon,
  Report,
  Shapes,
  Transaction,
  Users,
  Wallet,
  FileExportIcon,
  SupportIcon,
} from "../../icons/Menu";
import { CalendarIcon } from "@chakra-ui/icons";
import { BrokerAPIDocSrc, StatusPage, SupportPage } from "../../globals/consts";
import { MenuItem } from "./MenuItem";

const overview = { name: "Overview", path: "/dashboard", icon: Shapes };

const firmBalance = { name: "Firm Balance", path: "/balance", icon: Wallet };

const accounts = { name: "Accounts", path: "/accounts", icon: Users };

const transactions = (isOmnibus?: boolean) => ({
  name: "Transactions",
  path: "/transactions",
  redirectPath: "/transactions/transfers",
  icon: Transaction,
  submenu: [
    { name: "Transfers", path: "/transactions/transfers" },
    { name: "Orders", path: "/transactions/orders" },
    // Hide Assets and Journals from Omnibus correspondent setup
    ...(!isOmnibus
      ? [
          { name: "Assets", path: "/transactions/assets" },
          { name: "Journals", path: "/transactions/journals" },
        ]
      : []),
  ],
});

const reports = {
  name: "Reports",
  path: "/reports",
  icon: FileExportIcon,
};

const countryRisk = {
  name: "Country Risk",
  path: "/country-risk",
  icon: CountryRiskIcon,
};

const corporateActions = {
  name: "Corporate Actions",
  path: "/corporate-actions",
  icon: CalendarIcon,
  fontSize: "xl",
};

const documents = {
  name: "Documents",
  path: "/documents",
  icon: Report,
};

const support = {
  name: "Support",
  path: "/support",
  redirectPath: "/support/tickets",
  icon: SupportIcon,
  submenu: [{ name: "Tickets", path: "/support/tickets" }],
};

const devs = (isSandbox?: boolean) => ({
  name: "API/Devs",
  path: "/dev",
  icon: Api,
  // Only display Quickstart and Testing in Sandbox
  ...(isSandbox && {
    submenu: [
      { name: "Quick Start", path: "/dev/quick-start" },
      { name: "Testing", path: "/dev/live-testing" },
    ],
  }),
});

const teamSettings = { name: "Team Settings", path: "/team-settings" };

const resources = {
  name: "Help & Resources",
  submenu: [
    { name: "Support", path: SupportPage },
    { name: "Documentation", path: BrokerAPIDocSrc },
    { name: "Status Page", path: StatusPage },
  ],
};

export const leftMenuItems = ({
  isSandbox,
  isOmnibus,
  correspondent,
}: {
  isSandbox: boolean;
  isOmnibus: boolean;
  correspondent: string;
}): MenuItem[] => [
  overview,
  firmBalance,
  // Hide Accounts from Omnibus correspondent setup
  ...(!isOmnibus ? [accounts] : []),
  transactions(isOmnibus),
  reports,
  // Hide Country Risk from Omnibus correspondent setup
  ...(!isOmnibus ? [countryRisk] : []),
  // Hide CA from Onmibus and Sandbox
  ...(!isOmnibus && !isSandbox ? [corporateActions] : []),
  documents,
  support,
  devs(isSandbox),
];

export const FOOTER_MENU_ITEMS: (
  | { name: string; path: string; submenu?: undefined }
  | {
      name: string;
      submenu: { name: string; path: string }[];
      path?: undefined;
    }
)[] = [teamSettings, resources];
