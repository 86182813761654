/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from "moment";
import numeral from "numeral";
import {
  Order,
  Transfer,
  Document,
  Journal,
  Account,
  UploadedDocument,
} from "../api/types";
import { TableValue } from "../components/general/Table";
import { AccountTableRow } from "./types";
import { isArray } from "lodash";

const DEFAULT_TIMEZONE = "America/New_York";

export const capitalize = (value: string | undefined, split = true): string => {
  if (!value) return "";
  if (split) value = value.split("_").join(" ");
  return value
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const ordersToTableRows = (
  orders: (Order | undefined)[] | undefined,
  includeAccountID = false,
  currency: string
): (string | moment.Moment)[][] => {
  if (!orders) return [];

  return orders.map((order) => {
    const row = [
      order?.id || "",
      capitalize(order?.side || ""),
      order?.symbol || "",
      order?.filled_qty?.toString() ?? "",
      fmtLCTMoney(order?.notional, currency),
      capitalize(order?.status || ""),
      order?.submitted_at || "",
      order?.updated_at || "",
      order?.expires_at || "",
    ];
    if (includeAccountID) row.unshift(order?.account_id || "");
    return row;
  });
};

// Get a cash formatted string from a number or string
export const cashFormat = (num: number | string): string => {
  return numeral(num || "0.00").format("$0,0.00");
};

export const fmtLCTMoney = (
  value: string | number | undefined,
  currency: string | undefined
): string => {
  if (!value || !currency) {
    return "";
  }
  return `${currency} ${numeral(value).format("0,0.00")}`;
};

export const fmtQuantity = (value?: string | number): string =>
  numeral(value).format("0,0.000000000");

// Transform accounts into AccountTableRows (modifies rows in place)
export const mapAccountsToRowsInPlace = (
  accounts: Account[],
  rows: Record<string, AccountTableRow>
): void => {
  accounts.map((account: Account) => {
    rows[account.id] = {
      name: account.name || "",
      email_address: account.contact?.email_address || "",
      id: account.id,
      // Default to last_equity if it exists
      equity: cashFormat(account.last_equity ?? "0.00"),
      status: capitalize(account.status.split("_").join(" ")),
      created_at: account.created_at,
    };
  });
};

// Transform AccountTableRow[]s to TableValue[]s
export const mapAccountTableRowsToTableValues = (
  rows: AccountTableRow[]
): TableValue[][] => {
  return rows.map((row) => Object.values(row));
};

export const txsToTableRows = (
  transfers: (Transfer | undefined)[] | undefined,
  includeAccountID = true,
  currency: string
): (string | moment.Moment)[][] => {
  if (!transfers) return [];

  return transfers.map((t) => {
    const row = [
      t?.id || "",
      capitalize(t?.direction || ""),
      t?.type.toUpperCase() || "",
      fmtLCTMoney(t?.amount, currency),
      t?.status || "",
      t?.updated_at || "",
    ];
    if (includeAccountID) row.unshift(t?.account_id || "");
    return row;
  });
};

export const docsToTableRows = (
  documents: (Document | undefined)[] | undefined,
  withActID = true
): string[][] => {
  if (!documents) return [];
  if (withActID)
    return documents.map((doc) => [
      doc?.id || "",
      doc?.account_id || "",
      doc?.type?.split("_").join(" ") || "",
      doc?.date || "",
    ]);
  return documents.map((doc) => [
    doc?.id || "",
    doc?.type?.split("_").join(" ") || "",
    doc?.date || "",
  ]);
};

export const uploadedDocsToTableRows = (
  documents: UploadedDocument[]
): string[][] => {
  return documents.map((doc) => [
    doc.id,
    doc.doc_type,
    doc.doc_sub_type,
    doc.requested_at || "",
    doc.uploaded_at || "",
    doc.requested_by_admin_id || "",
    doc.uploaded_by_admin_id || "",
  ]);
};

export const journalsToTableRows = (
  journals: (Journal | undefined)[] | undefined,
  type = "JNLC"
): (string | moment.Moment)[][] => {
  if (!journals) return [];
  if (type === "JNLC")
    return journals.map((j) => [
      j?.id || "",
      j?.entry_type || "",
      j?.from_account || "",
      j?.to_account || "",
      numeral(j?.net_amount).format("$0,0.00"),
      j?.status || "",
      j?.settle_date || "",
    ]);

  return journals.map((j) => [
    j?.id || "",
    j?.entry_type || "",
    j?.symbol || "",
    numeral(j?.qty).format("0.00"),
    j?.from_account || "",
    j?.to_account || "",
    "",
    "",
  ]);
};

export const getDates = (
  from: moment.Moment,
  to: moment.Moment,
  inclusive?: boolean
): moment.Moment[] => {
  const dates = [];
  const f = from.clone();

  while (f.isBefore(to)) {
    dates.push(f.clone());
    f.add(1, "d");
  }

  if (inclusive) {
    dates.push(to.clone());
  }

  return dates;
};

export const formatJSON = (j: any): string => {
  if (typeof j === "string") j = JSON.parse(j);
  return JSON.stringify(j, null, "\t");
};

export const firmAccountName = (accountNum: string): string => {
  // Account number are suffix with the account type at the
  // end to the account number or example 1234567SW
  const accountType = accountNum?.slice(-2) ?? "unknown";

  switch (accountType) {
    case "SW":
      return "Sweep";
    case "DP":
      return "Clearing Deposit";
    case "TD":
      return "Tech Deposit";
    case "WD":
    case "WW":
      return "Withdraw";
    case "RD":
      return "Reserve Deposit";
    case "RW":
      return "Rewards";
    case "JS":
      return "Stock Journals";
    case "CR":
      return "Settlement Account";
    case "ER":
      return "Error";
    case "MF":
      return "Management Fees";
    case "SO":
      return "Short";
    case "LO":
      return "Long";
    case "JT":
      return "JIT Pre-Funding";
    case "BR":
      return "Billing Receivables";
    case "CP":
      return "Commissions Payable";
    default:
      return "Firm";
  }
};

export const setCookie = (name: string, value: string, days: number): void => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

export const getCookie = (name: string): string => {
  const nameEQ = name + "=";
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    while (cookie.charAt(0) == " ") cookie = cookie.substring(1, cookie.length);
    if (cookie.indexOf(nameEQ) == 0)
      return cookie.substring(nameEQ.length, cookie.length);
  }
  return "";
};

export const eraseCookie = (name: string): void => {
  document.cookie = name + "=; Max-Age=-99999999;";
};

interface DateObject {
  year: number;
  month: number;
  day: number;
}

export const formatDateObject = (date: DateObject | undefined): string => {
  if (!date) {
    return "-";
  }

  // Only year and days 1-indexed
  return moment({ ...date, month: date.month - 1 }).format("YYYY-MM-DD");
};

export const fmtCellValue = (
  value: CallableFunction | string | boolean | undefined
): string | undefined => {
  if (typeof value === "function") {
    return value();
  }

  if (typeof value == "boolean") {
    return value.toString();
  }

  // if no value default to a hyphen
  if ((value ?? "").length < 1) {
    return "-";
  }

  // spread array into multiple JSON stringified lines
  if (isArray(value)) {
    return value
      .map((v: any) => JSON.stringify(v, null, 2))
      .join("\n")
      .replaceAll('"', "");
  }

  // if type is object, stringify and indent
  // if type is boolean, display true or false
  if (typeof value === "object") {
    return JSON.stringify(value, null, 2);
  }

  return value;
};

export const formatDateRange = (start?: string, end?: string): string => {
  if (!start || !end) {
    return "-";
  }

  return [start, end].map((d) => moment(d).format("MMM DD, YYYY")).join(" - ");
};

export function getDateOrUndefined(
  value: any,
  format?: string
): Date | undefined {
  return value ? moment(value, format).toDate() : undefined;
}

export const formatDate = (
  date: Date | string | undefined | null,
  format = "YYYY-MM-DD HH:mm:ss z",
  timezoneAdjustment = true
) => {
  if (!date) {
    return "-";
  }

  if (timezoneAdjustment) {
    return moment(date).tz(DEFAULT_TIMEZONE).format(format);
  }

  return moment(date).format(format);
};

export const getAccountStatusColor = (status?: string): string => {
  switch (status) {
    case "PENDING":
    case "ACTION_REQUIRED":
    case "APPROVAL_PENDING":
    case "PENDING_CHANGE":
    case "ONBOARDING":
      return "warning";
    case "ACTIVE":
      return "success";
    case "INACTIVE":
    case "ACCOUNT_CLOSED":
    case "REJECTED":
    case "SUBMISSION_FAILED":
      return "error";
    default:
      return "inherit";
  }
};
